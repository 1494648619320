import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { HubspotForm } from 'src/components/atoms/HubspotForm';
import { Card } from 'src/components/atoms/Card';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsHubspotForm');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {!!heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.map(({ id, heading, contentNode, formId, sfCampaignId }) => (
          <Grid key={id} verticalAlign="center" spacious {...customData?.gridProps}>
            <Column>
              <h2>{heading}</h2>
              <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}></div>
            </Column>
            <Column>
              <Card>
                <HubspotForm formId={formId} sfdcCampaignId={sfCampaignId} />
              </Card>
            </Column>
          </Grid>
        ))}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
